import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-input-select',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule],
  templateUrl: './input-select.component.html',
  styleUrl: './input-select.component.css',
})
export class InputSelectComponent implements OnInit, OnChanges {
  @Input() id!: string;
  @Input() label: string = '';
  @Input() controlName!: string;
  @Input() required: boolean = false;
  @Input() form!: FormGroup;
  @Input() placeholder: string = '';
  @Input() options: { value: string | number; label: string }[] = [];
  @Output() change = new EventEmitter<string | number | null>();
  control!: FormControl;
  showDropdown: boolean = false;
  selectedLabel: string = '';

  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.control = this.form.get(this.controlName) as FormControl;
    this.updateSelectedLabel(this.control.value);

    this.control.valueChanges.subscribe(value => {
      this.updateSelectedLabel(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['form'] || changes['controlName']) {
      this.control = this.form.get(this.controlName) as FormControl;
    }

    if (changes['options'] || changes['form']) {
      this.updateSelectedLabel(this.control?.value);
    }
  }

  toggleDropdown(): void {
    this.showDropdown = !this.showDropdown;
  }

  selectOption(option: {
    value: string;
    label: string;
    img: string | null;
  }): void {
    this.control.setValue(option.value);
    this.selectedLabel = option.label;
    this.showDropdown = false;
    this.change.emit(option.value);
  }

  clearSelection(): void {
    this.control.setValue(null);
    this.showDropdown = false;
    this.change.emit(null);
  }

  onBlur(): void {
    setTimeout(() => {
      this.showDropdown = false;
    }, 100);
  }

  private updateSelectedLabel(value: any): void {
    if (value === null || value === undefined) {
      this.selectedLabel = '';
    } else {
      this.selectedLabel =
        this.options.find(opt => opt.value === value)?.label || '';
    }
    this._cdr.markForCheck();
  }
}
